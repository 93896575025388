import NextErrorComponent from "next/error"
import { captureException, flush } from "@sentry/node"

const ErrorPage = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    captureException(err)
  }

  return <NextErrorComponent statusCode={statusCode} />
}

ErrorPage.getInitialProps = async ({ res, err, asPath }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err
  })

  errorInitialProps.hasGetInitialPropsRun = true

  if (res?.statusCode === 404) {
    return { statusCode: 404 }
  }
  if (err) {
    captureException(err)
    await flush(2000)
    return errorInitialProps
  }

  captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))
  await flush(2000)

  return errorInitialProps
}

export default ErrorPage
